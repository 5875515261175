import React from 'react'
import {
  BlogContainer,
  Card,
  CenteredHeader,
  Section,
  SecondaryHeader,
  CenterLink,
  BlogsImageContainer,
  BlogLink,
} from 'src/utils/styles/blogs'
import PropTypes from 'prop-types'
import LoadingAnimation from 'src/components/LoadingAnimation'
import SEO from 'src/components/seo'

import Analytics from 'src/utils/Analytics'

class BlogHome extends React.Component {
  componentDidMount() {
    Analytics.track('Viewed The Skylight Blog Landing Page')
  }

  render() {
    const { pageContext } = this.props
    if (!pageContext?.blogPosts) return <LoadingAnimation />
    const { blogPosts } = pageContext
    const blogPostsDisplayed = []
    blogPosts.forEach((blogPost) => {
      if (blogPost.fields.show_on_blog_homepage) {
        blogPostsDisplayed.push(blogPost)
      }
    })
    return (
      <Section>
        <SEO
          title="The Skylight Blog"
          description="Discover new ways to simplify family life with how-to guides, tips and tricks for common struggles, solutions for keeping loved ones connected, and more."
        />
        <CenteredHeader>The Skylight Blog</CenteredHeader>
        <BlogContainer>
          {blogPostsDisplayed.map((content) => (
            <BlogLink href={`/${content.slug}`} key={content.slug}>
              <Card>
                <BlogsImageContainer>
                  {content.fields.sections[0] && content.fields.sections[0].image ? (
                    <img src={content.fields.sections[0].image} alt={content.fields.seo.title} />
                  ) : null}
                </BlogsImageContainer>
                <SecondaryHeader>{content.fields.seo.title}</SecondaryHeader>
                <CenterLink>
                  <p>Read More </p>
                </CenterLink>
              </Card>
            </BlogLink>
          ))}
        </BlogContainer>
      </Section>
    )
  }
}

BlogHome.propTypes = {
  pageContext: PropTypes.shape({
    blogPosts: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        fields: PropTypes.shape({
          show_on_blog_homepage: PropTypes.bool.isRequired,
          seo: PropTypes.shape({
            title: PropTypes.string.isRequired,
            meta_description: PropTypes.string.isRequired,
          }),
          hero: PropTypes.shape({
            headline: PropTypes.string.isRequired,
            cta_button_link: PropTypes.string.isRequired,
            cta_button_text: PropTypes.string.isRequired,
          }),
          sections: PropTypes.arrayOf(
            PropTypes.shape({
              image: PropTypes.string,
              image_title: PropTypes.string,
              text: PropTypes.string.isRequired,
            })
          ),
          footer: PropTypes.shape({
            cta: PropTypes.string,
          }),
        }),
      })
    ),
  }),
}
export default BlogHome
